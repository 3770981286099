<template>
    <div v-if="login">
        <div v-if="usersArr.length == 0" class="edicion_block mt20">
            <div class="form-group">{{ $t('firmas.act2') }}</div>
        </div>
        Displaying {{ filteredSignaturesCount }} signatures<br>
        <div class="row mt-0 ml-0">
            <div style="vertical-align: middle;display: inline-block; line-height: 28px;">Show only signatures with picture: &nbsp;</div>
            <vs-switch v-model="active2" style="vertical-align: middle;">
            </vs-switch>

            <div style="vertical-align: middle;display: inline-block; line-height: 28px; margin-left: 5px;">Show only active: &nbsp;</div>
            <vs-switch v-model="showOnlyActive" style="vertical-align: middle;">
            </vs-switch>
        </div>
        <div class="row mt-0 ml-0" style="margin-bottom: 10px; padding-bottom: 10px; padding-top: 10px;">
            <button type="button" class="btn btn-primary btn-sm"
            @click="send_notify_signature({ uid: getUser.uid, email: null })">
            <font-awesome-icon v-if="!loading" :icon="['fas', 'sync-alt']" />
            <font-awesome-icon v-if="loading" icon="circle-notch" spin />
            {{ $t('firmas.act1_n') }}</button>
        <span>{{ status }}</span>
        </div>
        <div style="overflow-y: scroll; max-height:100vh; padding-bottom: 500px;">
            <div v-for="(cardVal, index) in filteredUsers" :key="cardVal.id"
                v-if="(cardVal.info.nombre && cardVal.info.trabajo && cardVal.info.img && cardVal.info.email) || active2" class="row mt-0 ml-0">
                <vs-avatar v-if="cardVal.info.img">
                    <img :src="cardVal.info.img" alt="">
                </vs-avatar>
                <vs-avatar v-else danger>
                </vs-avatar>
                <div class="form-group col-sm-3" v-if="cardVal.info.nombre && cardVal.info.nombre != ''">
                    <input class="form-control dark" type="text" maxlength="50" v-model="cardVal.info.nombre">
                </div>
                <div class="form-group col-sm-3" v-else>
                    <input class="form-control" style="background-color: rgb(var(--vs-danger));" type="text" maxlength="50" v-model="cardVal.info.nombre">
                </div>
                <div class="form-group col-sm-3" v-if="cardVal.info.trabajo && cardVal.info.trabajo != ''">
                    <input class="form-control dark" type="text" maxlength="50" v-model="cardVal.info.trabajo">
                </div>
                <div class="form-group col-sm-3" v-else>
                    <input class="form-control" style="background-color: rgb(var(--vs-danger));" type="text" maxlength="50" v-model="cardVal.info.trabajo">
                </div>
                <div class="form-group col-sm-3" v-if="cardVal.info.email && cardVal.info.email != ''">
                    <input class="form-control dark" type="text" maxlength="50" v-model="cardVal.info.email">
                </div>
                <div class="form-group col-sm-3" v-else>
                    <input class="form-control" style="background-color: rgb(var(--vs-danger));" type="text" maxlength="50" v-model="cardVal.info.email">
                </div>
                <div class="form-group col-sm-2 d-flex align-items-center">
                    <Icon icon="ph:eye-light" style="font-size: 24px;" @click.native="modalSignature(cardVal)" class="manito"/>
                    <Icon icon="ph:envelope-light" style="margin-left:10px; font-size: 24px;" @click.native="send_notify_signature({ uid: getUser.uid, email: cardVal.info.email })" class="manito"/>
                    <Icon icon="ph:copy-light" style="margin-left:10px; font-size: 24px;"  @click.native="copy_notify_signature({ uid: getUser.uid, email: cardVal.info.email, id: cardVal.id, hola: true })" class="manito"/>
                    <Icon icon="ph:pencil" style="margin-left:10px; font-size: 24px;"  @click.native="open_linkcard({ uid: getUser.uid, email: cardVal.info.email, id: cardVal.id, hola: true })" class="manito"/>

                    <div v-show="false" id="firma_wrapper" bgcolor="#fff"
                        :class="$util.hasSignaBanner(getUser.signature) ? 'conbanner' : 'sinbanner'">
                        <table :id="'firma_wrapper_tables_' + cardVal.id" border="0" cellspacing="0" cellpadding="0">
                            <templates :profile="getUser" :signature="getUser.signature" :linkcard="cardVal"
                                :profile_photo='!(cardVal.style && cardVal.style.firma_new && cardVal.style.firma_new.active)' />
                            <tfoot v-if="$util.hasSignaPartner(cardVal.info)" class="pvw_banner"
                                style="display:block;margin-top:10px;">
                                <tr valign="top" style="">
                                    <td v-if="cardVal.info.xai"><a target="_blank" style="display:block;"
                                            :href="$util.sanitizar(cardVal.info.xai, 'xai')"><img
                                                src="@/assets/images/signa_partners/xai.gif" width="145px"
                                                alt="signature_banner" /></a></td>
                                    <td v-if="cardVal.info.xai" width="15">
                                        <div style="width: 15px;"></div>
                                    </td>
                                    <td v-if="cardVal.info.whereby"><a target="_blank" style="display:block;"
                                            :href="$util.sanitizar(cardVal.info.whereby, 'whereby')"><img
                                                src="@/assets/images/signa_partners/whereby.gif" width="145px"
                                                alt="signature_banner" /></a></td>
                                    <td v-if="cardVal.info.whereby" width="15">
                                        <div style="width: 15px;"></div>
                                    </td>
                                    <td v-if="cardVal.info.calendarcom" style=""><a target="_blank" style="display:block;"
                                            :href="$util.sanitizar(cardVal.info.calendarcom, 'calendarcom')"><img
                                                src="@/assets/images/signa_partners/calendarcom.gif" width="145px"
                                                alt="signature_banner" /></a></td>
                                    <td v-if="cardVal.info.calendarcom" width="15">
                                        <div style="width: 15px;"></div>
                                    </td>
                                </tr>
                            </tfoot>
                            <tfoot v-if="$util.hasSignaBanner(getUser.signature)" class="pvw_banner"
                                style="display:block;margin-top:20px;">
                                <tr valign="top" style="">
                                    <td style=""><a target="_blank" style="display:block;"
                                            :href="$util.sanitizar(getUser.signature.url)"><img
                                                :src="$util.getSignaBanner(getUser.signature)"
                                                :style="'width:' + (getUser.signature.banner.width) + 'px'"
                                                :width="getUser.signature.banner.width" alt="signature_banner" /></a></td>
                                </tr>
                            </tfoot>
                            <tfoot v-if="getUser.signature.desc" class="pvw_banner"
                                style="display:block;margin-top:5px;max-width:600px;">
                                <tr valign="top">
                                    <td>
                                        <p style="display:block;font-size:10px;margin-top:10px;">{{ getUser.signature.desc
                                        }}
                                        </p>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import templates from "@/layouts/signature_Customizer/templates.vue"
import { db, functions } from '@/services/firebaseConfig.js';
import { Icon } from "@iconify/vue2"

export default {
    data() {
        return {
            login: true,
            loading: false,
            getUser: this.$store.state.userProfile,
            usersArr: [],
            status: '',
            active2: false,
            showOnlyActive: true
        }
    },
    computed: {
        filteredUsers() {
            const activeFilter = this.showOnlyActive 
                ? this.usersArr.filter(card => card.status != 'deleted')
                : this.usersArr;
            
            return activeFilter;
        },
        filteredSignaturesCount() {
            return this.filteredUsers.filter(cardVal => 
                (cardVal.info.nombre && 
                cardVal.info.trabajo && 
                cardVal.info.img && 
                cardVal.info.email) || 
                this.active2
            ).length;
        }
    },
    async mounted() {
        let dis = this
        this.init()
    },
    methods: {
        async copy_notify_signature(data = { uid: 'EiS1qlW4bFMBJlZ6yPWkab2lFFJ3', email: null }) {
            navigator.clipboard.writeText(`https://my.linkcard.app/signature?id=${data.id}&uid=${data.uid}`);
            this.$util.notif_success();
        },
        async send_notify_signature(data = { uid: 'EiS1qlW4bFMBJlZ6yPWkab2lFFJ3', email: null }) {
            let dis = this
            dis.loading = true
            await functions.httpsCallable('send_notify_signature')({ email: data.email, uid: data.uid })
                .then(function (result) {
                    dis.$util.notif_success();
                }).catch(e => {
                    return null
                })
            dis.loading = false
        },
        init() {
            this.usersArr = this.getUser.linckards;
        },
        open_linkcard({id}) {
            if (!this.$router) {
                console.warn('Router not available');
                return;
            }
            
            var dis = this;
            let index = dis.usersArr.findIndex(function (i) {
                return i.id == id;
            });
            let linc = dis.usersArr[index];
            
            const linkcardSector = this.getUser.sectors.filter(sector => sector.id === linc.sector_id)
            this.$store.commit("editPushInit", { linckard: linc, sectorType: linkcardSector.type });
            this.$router.push("/editor/" + linc.id);
        },
        modalSignature(linkcard) {
            let index = this.getUser.linckards.findIndex(function (i) { return i.id == linkcard.id; })
            if (this.getUser.linckards[index].style && !this.getUser.linckards[index].style.firma_new) this.getUser.linckards[index].style.firma_new = {}
            this.$modal.show('modal-firma', { linkcard: this.getUser.linckards[index] })
        },
        async push() {
            let dis = this
            dis.loading = true
        },
    },
    components: {
        templates, Icon
    }
}
</script>