
<template>
<client-only>
  <modal name="modal-dirinit"
        transition="nice-modal-fade"
        :min-width="200"
        class="dirinit"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-close="beforeClose">
         
   <div class="modal_wrapper " >
        <div  class="card " >
            <div class="card-body text-center">
                <img src="@/assets/images/Icon_Networking.svg" />
                <h3 class="text-center" style="color:#458EDD;">{{$t('directory.dir1')}}</h3>
                <small class="text-center mb20">{{$t('directory.dir2')}}</small>
                <ul class="dirinit text-left">
                    <li><img src="@/assets/images/Icon_Tilde.svg" />{{$t('directory.dir3')}}</li>
                    <li><img src="@/assets/images/Icon_Tilde.svg" />{{$t('directory.dir4')}}</li>
                    <li><img src="@/assets/images/Icon_Tilde.svg" />{{$t('directory.dir5')}}</li>
                    <li><img src="@/assets/images/Icon_Tilde.svg" />{{$t('directory.dir6')}}</li>
                </ul>

                <small class="text-center mb20">{{$t('directory.dir7')}}</small>
                <button @click="verPlanes()" class="btn btn-primary btn-initial " type="button" >{{$t('planes.ver')}}</button> 
            </div>
        </div>
    </div>
  </modal>
</client-only>
</template>
<script>
import { planes_businesscards} from '@/mixins/stripe.js'

export default {
    data () {
        return {
            type:'',
            getUser:this.$store.state.userProfile
        }
    },
    methods:{
        beforeClose(){
            this.$util.gotoHOME();
        },
        verPlanes(){
            this.$gtag.event( 'popup_show', {})
            this.$modal.show('modal-planes',{place:'dir_init',user:this.getUser,planes:planes_businesscards})
        },
    }
}
</script>

<style scoped>
ul.dirinit {
    padding:0px !important;
    list-style-type: none;
}
ul.dirinit li{
    line-height:50px;
}
ul.dirinit li img{
    margin-right:10px;
}
.card-body > img{
margin-bottom:25px !important;
}

.dirinit h3{
  font-weight: 300; 
    color: rgb(70, 70, 70);
}

ul.dirinit li {
    line-height: 45px !important;
font-weight: 400 !important;
    color: #494949 !important;
  
}
</style>