<template>
    <modal 
          name="modal-planes" 
          transition="nice-modal-fade" 
          :classes="['modal-planes', clases]"
          :pivot-y="0.5" 
          :adaptive="true"
          :scrollable="true" 
          :reset="true" 
          :width="modalWidth" 
          height="auto" 
          @before-close="beforeClose"
          @before-open="beforeOpen" 
          @opened="onOpened"
          :click-to-close="false"
          :draggable="false"
          :resizable="false"
          dismissible="false"
      >
          <div class="modal-content" :class="{'no-close': shouldPreventClose}">
              <div slot="top-right">
                  <button 
                      v-if="!shouldPreventClose"
                      @click="tryClose" 
                      class="ngdialog-close">
                  </button>
              </div>
              <planes v-if="isModalOpen" :key="modalKey" :place="place" ref="planesComponent" />
          </div>
      </modal>
  </template>
  
  <script>
  import planes from '@/components/planes.vue'
  import { stripePlans } from '../../plugins/utils';
  
  export default {
      data() {
          return {
              cantClose: true,
              planes: stripePlans,
              place: 'modal',
              width: this.$util.isMobile() ? '90%' : 1000,
              clases: 'ngdialog-content empresa',
              getUser: this.$store.state.userProfile,
              isModalOpen: false,
              modalKey: 0,
              windowWidth: window.innerWidth,
          }
      },
      computed: {
          modalWidth() {
              return this.windowWidth < 768 ? '90%' : '690px';
          },
          isLeadsorStatsorMembersSection() {
              return this.$route.path.includes('/leads') || this.$route.path.includes('/stats') || this.$route.path.includes('/members');
          },
          shouldPreventClose() {
              if (!this.isLeadsorStatsorMembersSection) return false;
              const result = this.$util.isTrialOrPro(null, this.place);
              return !result;
          }
      },
      mounted() {
          window.addEventListener('resize', this.onResize);
          window.addEventListener('keydown', this.preventClose);
          this.addClickOutsideListener();
      },
      beforeDestroy() {
          window.removeEventListener('resize', this.onResize);
          window.removeEventListener('keydown', this.preventClose);
          this.removeClickOutsideListener();
      },
      methods: {
          addClickOutsideListener() {
              setTimeout(() => {
                  const overlay = document.querySelector('.v--modal-overlay');
                  if (overlay) {
                      overlay.addEventListener('click', this.handleClickOutside);
                  }
              }, 100);
          },
          removeClickOutsideListener() {
              const overlay = document.querySelector('.v--modal-overlay');
              if (overlay) {
                  overlay.removeEventListener('click', this.handleClickOutside);
              }
          },
          handleClickOutside(e) {
              if (this.shouldPreventClose && !e.target.closest('.v--modal-box')) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
              }
          },
          preventClose(e) {
              if (this.shouldPreventClose && (e.key === 'Escape' || e.keyCode === 27)) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
              }
          },
          tryClose() {
              if (!this.shouldPreventClose) {
                  this.$modal.hide('modal-planes');
              }
          },
          onResize() {
              this.windowWidth = window.innerWidth;
          },
          beforeClose(event) {
              if (this.shouldPreventClose) {
                  event.stop();
                  event.preventDefault();
                  return false;
              }
          },
          beforeOpen(event) {
              this.cantClose = event.params.close ? true : false
              this.place = event?.params?.place || 'bc';
              if (event.params.user) this.getUser = event.params.user
              if (event.params.planes) this.planes = event.params.planes
              this.isModalOpen = false;
              this.modalKey += 1;
              this.$nextTick(() => {
                  this.addClickOutsideListener();
              });
          },
          onOpened() {
            this.$gtag.event( 'popup_show', {})

              this.isModalOpen = true;
              this.$nextTick(() => {
                  if (this.$refs.planesComponent && typeof this.$refs.planesComponent.resetIframe === 'function') {
                      this.$refs.planesComponent.resetIframe();
                  }
              });
          }
      },
      components: {
          planes
      }
  }
  </script>
<style>
.v--modal-overlay {
    z-index: 99999 !important;
    pointer-events: all !important;
}

.v--modal-box {
    z-index: 100000 !important;
    pointer-events: all !important;
    filter: none !important;
    position: relative !important;
}

#LeftNav,
.left_nav,
.nav_wrapper {
    position: relative;
    z-index: 100001 !important;
    filter: none !important;
}

.main_content {
    transition: filter 0.3s ease;
}

body.v--modal-block-scroll .main_content {
    filter: blur(5px);
}

.modal-content {
    filter: none !important;
}

.no-close .ngdialog-close {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
}

.left_nav, 
.left_nav *,
.primary_menu,
.primary_menu *,
.secondary_menu,
.secondary_menu * {
    pointer-events: auto !important;
    filter: none !important;
}

.modal-planes .v--modal-box {
    opacity: 1 !important;
    visibility: visible !important;
    display: block !important;
    transform: none !important;
}

body.modal-open {
    overflow: hidden;
}

@media (max-width: 768px) {
    .v--modal-box {
        width: 90% !important;
        margin: 10px !important;
    }
}


.panelContent:not(#LeftNav) {
    transition: filter 0.3s ease;
}

body.v--modal-block-scroll .panelContent:not(#LeftNav):not(.left_nav) {
    filter: blur(5px);
}

.v--modal-box .modal-content,
.v--modal-box .modal-content * {
    filter: none !important;
}

.left_nav,
#LeftNav,
.left_nav *,
#LeftNav * {
    filter: none !important;
    backdrop-filter: none !important;
}

.modal-planes,
.modal-planes * {
    backdrop-filter: none !important;
}
</style>