<template>
<div>
	<label>{{$t('pickers.opacity')}}</label>
	<vue-slider 
 	@drag-start="$store.state.isLiveEditing=true" 
 	@drag-end="sendlast()" 
	:min="1"
	v-model="inputVal"
	style="width: 85%!important;" />
</div>
</template>



<script>

export default {
	props: ['value'],
	data() {
		return { 
			inputVal: this.value*100
		}
	},
	methods:{
		sendlast(){
			this.$store.state.isLiveEditing=false;
			var dis=this
			let val=this.inputVal/100;

			//Fix a la velocidad de la luz
			dis.$emit('input', '');
			dis.$emit('input', val);
		}
	},
	watch: {
		inputVal(val) {
			val=val/100;
			this.$emit('input', val);
		}
	},
}
</script>