
<template>
<client-only>
  <modal name="modal-churn"
        transition="nice-modal-fade"
        :clickToClose="false"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="500"
        height="auto"
        @before-open="beforeOpen">
    <div class="modal_wrapper " >
        <form   class="card " novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h3 class="text-center">{{$t('qachurn.why')}}</h3>
                <p class="text-center">{{$t('qachurn.whysub')}}</p>
                <div class="form-group "><input name="name" type="radio" id="1" :value="$t('qachurn.q1')" v-validate="{ required: true}" v-model="value"> <label for="1">{{$t('qachurn.q1')}}</label></div>
                <div class="form-group "><input name="name" type="radio" id="2" :value="$t('qachurn.q2')" v-validate="{ required: true}" v-model="value"> <label for="2">{{$t('qachurn.q2')}}</label></div>
                <div class="form-group "><input name="name" type="radio" id="3" :value="$t('qachurn.q3')" v-validate="{ required: true}" v-model="value"> <label for="3">{{$t('qachurn.q3')}}</label></div>
                <div class="form-group "><input name="name" type="radio" id="4" :value="$t('qachurn.q4')" v-validate="{ required: true}" v-model="value"> <label for="4">{{$t('qachurn.q4')}}</label></div>
                <div class="form-group "><input name="name" type="radio" id="5" :value="$t('qachurn.q5')" v-validate="{ required: true}" v-model="value"> <label for="5">{{$t('qachurn.q5')}}</label></div>
                <div class="form-group "><input name="name" type="radio" id="7" :value="$t('qachurn.q7')" v-validate="{ required: true}" v-model="value"> <label for="7">{{$t('qachurn.q7')}}</label></div>
                <div class="vuerror" v-if="errors.has('name')" >{{ errors.first('name') }}</div>
                <div class="form-group ">
                    <textarea 
                    :placeholder="$t('qachurn.add')"
                    v-model="desc"
                    style="height: 90px;
                    border:0px !important;
                    width:100% !important;
                    color:grey !important;
                    display: inline-block;
                    white-space: normal;
                    color: rgb(73, 80, 87);
                    font-size: 12px;
                    line-height: 1.5;
                    padding: 10px 15px;
                    border-radius: 5px;
                    background-color: rgb(237, 237, 237);" />
                </div>
                <div class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100"  type="submit"  :is-loading="loading" :disabled="loading" :status="status"> {{$t('general.guardar')}} </button-spinner>
                </div>

            </div>
        </form>
      
        
    </div>
  </modal>
</client-only>
</template>
<script>
import { planes_businesscards} from '@/mixins/stripe.js'
import { Validator } from "vee-validate";
import {functions,db } from '@/services/firebaseConfig.js';

export default {
    data () {
      return {
        value:'',
        desc:'',
        qa:{},
        getUser:this.$store.state.userProfile,
        loading:false,
        status:'',
      }
    },
    methods: {
        beforeOpen (event) {   
            var dis=this
        },
        async save(){
            var dis=this
            this.getUser.account_details.churn={}
            this.getUser.account_details.churn.why=this.value
            this.getUser.account_details.churn.desc=this.desc

            await db.collection('users').doc(this.getUser.uid).update({account_details:this.getUser.account_details})
            
            let product=planes_businesscards[0]
            const updateSubscriptions = functions.httpsCallable('updateSubscriptions');
            updateSubscriptions({uid:dis.getUser.uid,source:null,plan_id:product.plans[0].plan_id,prod_id:product.prod_id,type:product.type,customer_id:dis.getUser.stripe_details.stripe_customer_id})
            .then(function(result) {

                dis.loading = false
                dis.$modal.hide('modal-churn') 
                dis.$modal.hide('dialog')
                dis.getUser.stripe_details=result.data
                
                dis.$util.notif_success(dis.$t('notis.planact'));
            }).catch(function(error) {
                dis.$util.notif_error(error.message);
            });
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll().then((result) => {
                if(result){
                    dis.save()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
    },
  }
</script>

<style scoped>
.form-group input {
    display: inline-block;
}
.listaagentes{
    max-height: 450px !important;
    overflow-y: scroll;
}
.card-body h3{
    font-size:20px;
    text-align: left;
    margin-top:10px;
    color:#448FDE;
}
</style>