<template>
  <div class="" id="edicionRight">
    <div
      v-if="loading"
      class="row ml-0 mr-0 template_list "
      style="text-align:center;"
    >
      <div class="wrapper_middle mt40" style="margin:0 auto;">
        <img src="@/assets/images/loading.gif" width="100" />
      </div>
    </div>
    <div
      v-if="quien == 'link' && preview.isLanding && !loading"
      class="edicion_block w-90"
      style="width:60%;margin:0 auto;display:block;"
    >
      <!-- <h4>{{$t('general.categories')}}</h4>-->
      <div class="row form_row ">
        <select v-if="!['bc', 'lib'].includes($store.state.sectorType)" class="form-control" id="comboMostrar" v-model="category">
          <option v-for value="all">{{ $t("filtros.select") }}</option>
          <template v-for="item in categorias">
            <option :key="item" :value="item">{{ item }}</option>
          </template>
        </select>
      </div>
    </div>
    <div v-if="!loading" class="row ml-0 mr-0 template_list ">
      <li
        v-if="quien != 'link' || $util.isFounder(getUser)"
        class="col-6 template_col"
      >
        <div class="template_block">
          <div class="template_head" style="min-height: 30px;"></div>
          <!-- modificado ENE 2020 -->
          <a
            @click="enable_saveTemplate()"
            class="template_sample template_empty"
          >
            <!--<img src="@/assets/images/template_sample_default.png" class="w-100">-->
            <div class="template_add_wrapper">
              <span><img src="@/assets/images/icon_add_white.svg"/></span>
              <b
                >{{ $t("plantilla.agrega") }}
                <font-awesome-icon
                  v-if="!$util.isTrialOrPro()"
                  :icon="['fas', 'lock']"
              /></b>
            </div>
          </a>
          <!-- /modificado ENE 2020 -->
        </div>
      </li>
      <li
        v-for="(template, index) in templatesAll"
        :key="template.id"
        class="col-6 template_col"
      >
        <div
          class="template_block "
          :class="{ checked: isActive(template), premium: template.premium }"
        >
          <span
            v-if="template.tipo == 'pro'"
            class="label pro mt5 mb5"
            style="text-transform: capitalize;"
            >pro</span
          >
          <div class="template_head" style="min-height: 30px;">
            <div
              v-if="quien != 'link' || $util.isFounder(getUser)"
              class="template_actions_wrapper"
            >
              <ul class="template_actions">
                <template v-if="$util.isFounder()"
                  >{{ template.id }}<br
                /></template>
                <li>
                  <button
                    @click="sobreescribirTemplate(template)"
                    class="btn_action_success"
                  >
                    <font-awesome-icon :icon="['fas', 'save']" />
                  </button>
                </li>
                <li>
                  <button
                    @click="eliminarTemplate(template)"
                    class="btn_action_danger"
                  >
                    <font-awesome-icon :icon="['fas', 'trash']" />
                  </button>
                </li>
                <li v-if="$util.isFounder()">
                  <button
                    @click="transferTemplate(template)"
                    class="btn_action_danger"
                  >
                    <font-awesome-icon :icon="['fas', 'copy']" />
                  </button>
                </li>
                <li v-if="$util.isFounder()">
                  <button
                    @click="applyTemplateAll(template)"
                    class="btn_action_danger"
                  >
                    <font-awesome-icon :icon="['fas', 'magic']" />
                  </button>
                </li>
                <li v-if="$util.isFounder()">
                  <button
                    @click="setCategory(template)"
                    class="btn_action_danger"
                  >
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </button>
                </li>
                <li v-if="$util.isTesting()">
                  <button
                    @click="loadTemplateALL(template)"
                    class="btn_action_danger"
                  >
                    <font-awesome-icon :icon="['fas', 'home']" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            @click="loadTemplate(template)"
            class="template_check pos_relative manito"
          >
            <img
              v-if="template.img"
              :src="template.img"
              style="border-radius:10px;"
              class="template_sample pthumb_parent_container"
            />
            <plantillaLight
              v-else-if="template.type == 'bc'"
              :info="template"
              :estilo="template.style"
              :type="template.type"
            />
            <landingLight
              v-else-if="template.type == 'mp'"
              :info="template"
              :estilo="template.style"
              :type="template.type"
            />
          </div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import { planes_businesscards } from "@/mixins/stripe.js";
import {
  auth,
  usersCollection,
  db,
  functions,
} from "@/services/firebaseConfig.js";

import imagePicker from "@/components/imagePicker";
import plantillaLight from "@/components/plantillaLight";
import landingLight from "@/components/landingLight";

export default {
  props: ["linckard", "profile", "type", "sectorType"],
  data() {
    return {
      loading: false,
      getUser: this.$store.state.userProfile,
      preview: this.linckard,
      templates: [],
      activeTemplate: null,
      quien: this.type,
      categorias: [],
      category: "all",
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    setCategory(linkcard) {
      this.$modal.show("modal-category", { linkcard: linkcard });
    },
    applyTemplateAll(template) {
      this.$modal.show("modal-applytemplateall", { template: template });
    },
    transferTemplate(linkcard) {
      this.$modal.show("modal-transfer", {
        linkcard: linkcard,
        isTemplate: true,
      });
    },
    enable_saveTemplate() {
      if (this.$util.isTrialOrPro(this.$modal, "save_template"))
        this.guardarTemplateNuevo();
    },

    fetch() {
      var dis = this;
      dis.loading = true;
      db.collection("templates")
        .where("from", "==", dis.quien)
        // .where("type", "==", this.preview.isLanding ? "mp" : "bc")
        .where("status", "==", "active")
        .orderBy("id", "desc")
        .get()
        .then(async (querySnapshot) => {
          console.log("querySnapshot", querySnapshot.docs)
          try {
            dis.templates = [];
            for (var i in querySnapshot.docs) {
              const doc = querySnapshot.docs[i];
              let temp = doc.data();

              if(!temp.category){
                dis.templates.push(temp)
              } else if( this.$store.state.sectorType === 'bc') {
                  if(temp.category === "Business Card" || temp.category === "Resume") {
                      dis.templates.push(temp)
                  }
              } else if ( this.$store.state.sectorType === 'lib'){
                  if(temp.category === "Social Media") {
                      dis.templates.push(temp)
                  }
              } else {
                  dis.templates.push(temp)
              }

              let cate = dis.$util.gettranslabel_entoes(temp.category);
              if (!dis.categorias.includes(cate) && cate)
                dis.categorias.push(cate);
            }
            dis.categorias = dis.categorias.sort();
            console.log('cant templates', dis.templates?.length,dis.quien)
            dis.loading = false;
            dis.templates = this.$util.shuffle(dis.templates);
          } catch (e) {
            console.log(e);
          }
        });
    },
    isActive(template) {
      if (!this.activeTemplate) return false;
      if (template.id == this.activeTemplate.id) return true;
    },

    loadTemplateALL(template) {
      var dis = this;
      this.$modal.show("dialog", {
        title: this.$t("notis.atencion"),
        text: this.$t("plantilla.load"),
        buttons: [
          {
            title: dis.$t("plantilla.apply"),
            handler: async () => {
              dis.activeTemplate = JSON.parse(JSON.stringify(template));
              if (template.id) dis.preview.template_id = template.id;
              if (template.category)
                dis.preview.template_category = template.category;
              dis.$modal.hide("dialog");
              dis.implementarDiseñoPlantillasALL(dis.activeTemplate);
            },
          },
          { title: dis.$t("plantilla.applyd") },
        ],
      });
    },

    loadTemplate(template) {
      var dis = this;
      if (
        (template.premium &&
          this.$util.isTrialOrPro(this.$modal, "template")) ||
        !template.premium
      ) {
        this.$modal.show("dialog", {
          title: this.$t("notis.atencion"),
          text: this.$t("plantilla.load"),
          buttons: [
            {
              title: dis.$t("plantilla.apply"),
              handler: async () => {
                dis.activeTemplate = JSON.parse(JSON.stringify(template));
                if (template.id) dis.preview.template_id = template.id;
                if (template.category)
                  dis.preview.template_category = template.category;
                dis.$modal.hide("dialog");
                dis.implementarDiseñoPlantillas(dis.activeTemplate);
              },
            },
            { title: dis.$t("plantilla.applyd") },
          ],
        });
      }
    },
    implementarDiseñoPlantillasALL(template) {
      var dis = this;
      dis.$store.getters.editingLinckard.style = template.style;
      let drags = dis.$store.getters.editingLinckard.sobredrags;
      if (drags.length == 0) {
        dis.$store.getters.editingLinckard.sobredrags = template.sobredrags;
        dis.$bus.$emit("refresh_drags", template.sobredrags);
      } else if (drags.length > 0) {
        this.$modal.show("dialog", {
          title: this.$t("notis.atencion"),
          text: this.$t("plantilla.load2"),
          buttons: [
            {
              title: dis.$t("notis.si"),
              handler: async () => {
                dis.implementaratodas(template.sobredrags);
                dis.$modal.hide("dialog");
              },
            },
            { title: "No" },
          ],
        });
      } else {
        dis.implementarSobrePlantillas(template, drags);
      }
    },
    async implementaratodas(drags) {
      var dis = this;
      dis.$bus.$emit("refresh_drags", drags);
      dis.$store.getters.editingLinckard.sobredrags = drags;
      for (let i in dis.getUser.linckards) {
        dis.getUser.linckards[i].sobredrags = drags;
      }

      await this.$util.E(dis.getUser.linckards);
      dis.$util.notif_success();
    },

    implementarDiseñoPlantillas(template) {
      var dis = this;
      dis.$store.getters.editingLinckard.style = template.style;
      let drags = dis.$store.getters.editingLinckard.sobredrags;
      if (drags.length == 0) {
        dis.$store.getters.editingLinckard.sobredrags = template.sobredrags;
        dis.$bus.$emit("refresh_drags", template.sobredrags);
      } else if (drags.length > 0) {
        this.$modal.show("dialog", {
          title: this.$t("notis.atencion"),
          text: this.$t("plantilla.load2"),
          buttons: [
            {
              title: dis.$t("notis.si"),
              handler: async () => {
                dis.$bus.$emit("refresh_drags", template.sobredrags);
                dis.$store.getters.editingLinckard.sobredrags =
                  template.sobredrags;
                dis.$modal.hide("dialog");
              },
            },
            { title: "No" },
          ],
        });
      } else {
        dis.implementarSobrePlantillas(template, drags);
      }
    },

    implementarSobrePlantillas(template, drags) {
      var dis = this;

      for (let i in drags) {
        let elDrag = drags[i];
        for (let j in template.sobredrags) {
          let laRefe = template.sobredrags[j];
          if (laRefe.compo == elDrag.compo) {
            dis.$store.getters.editingLinckard.sobredrags[i].color =
              laRefe.color;
            dis.$store.getters.editingLinckard.sobredrags[i].family =
              laRefe.family;
            dis.$store.getters.editingLinckard.sobredrags[i].lineh =
              laRefe.lineh;
            dis.$store.getters.editingLinckard.sobredrags[i].size = laRefe.size;
            dis.$store.getters.editingLinckard.sobredrags[i].weight =
              laRefe.weight;
            dis.$store.getters.editingLinckard.sobredrags[i].background =
              laRefe.background;
            break;
          }
        }
      }
    },
    async eliminarTemplate(eltemplate) {
      var dis = this;
      this.$modal.show("dialog", {
        title: this.$t("notis.atencion"),
        text: this.$t("general.estaseguro"),
        buttons: [
          {
            title: dis.$t("general.eliminar"),
            handler: async () => {
              dis.loading = true;
              dis.$modal.hide("dialog");
              await db
                .collection("templates")
                .doc(eltemplate.id + "")
                .update({ deleted: true, status: "deleted" })
                .then(function() {
                  dis.fetch();
                  dis.$util.notif_success(null);
                });
            },
          },
          { title: dis.$t("general.no") },
        ],
      });
    },
    async guardarTemplateNuevo() {
      console.log("Si, paso por aca")
      var dis = this;

      this.$modal.show("dialog", {
        title: this.$t("notis.atencion"),
        text: this.$t("plantilla.guardanueva"),
        buttons: [
          {
            title: this.$t("general.guardar"),
            handler: async () => {
              dis.loading = true;
              dis.$modal.hide("dialog");

              this.preview = await this.$util.uploadImages(this.preview);
              let last_id = await db
                .collection("templates")
                .get()
                .then(async (querySnapshot) => {
                  return querySnapshot.size;
                });
              console.log("last_id", last_id)
              let template = {
                id: last_id,
                type:
                  this.preview.loyalty_id >= 0
                    ? "loyalty"
                    : this.preview.event_id >= 0
                    ? "event"
                    : this.preview.isLanding
                    ? "mp"
                    : "bc",
                status: "active",
                style: this.preview.style,
                sobredrags: this.preview.sobredrags,
                from: dis.quien,
                deleted: false,
                date: dis.$util.getDate(),
                date_time: new Date().getTime(),
              };
              console.log("template", template)
              await db
                .collection("templates")
                .doc(last_id + "")
                .set(template)
                .then(function() {
                  dis.fetch();
                  dis.$util.notif_success(null);
                });
            },
          },
          { title: dis.$t("general.no") },
        ],
      });
    },
    async sobreescribirTemplate(eltemplate) {
      var dis = this;
      this.$modal.show("dialog", {
        title: this.$t("notis.atencion"),
        text: this.$t("plantilla.sobreescr"),
        buttons: [
          {
            title: dis.$t("general.guardar"),
            handler: async () => {
              dis.loading = true;
              dis.$modal.hide("dialog");
              Object.keys(dis.preview.style).forEach(
                (key) =>
                  dis.preview.style[key] === undefined &&
                  delete dis.preview.style[key]
              );

              for (let i in dis.preview.sobredrags) {
                let elemdrag = dis.preview.sobredrags[i];
                Object.keys(dis.preview.sobredrags[i]).forEach(
                  (key) =>
                    dis.preview.sobredrags[i][key] === undefined &&
                    delete dis.preview.sobredrags[i][key]
                );
              }

              this.preview = await this.$util.uploadImages(this.preview);

              await db
                .collection("templates")
                .doc(eltemplate.id + "")
                .update({
                  style: dis.preview.style,
                  sobredrags: dis.preview.sobredrags,
                })
                .then(function() {
                  dis.fetch();
                  dis.$util.notif_success();
                });
            },
          },
          { title: dis.$t("general.no") },
        ],
      });
    },
  },

  computed: {
    templatesAll() {
      let dis = this;
      let cate = this.$util.gettranslabel_estoen(dis.category);
      return this.templates.filter((u) => {
        return u.category == cate || dis.category == "all";
      });
    },
  },
  components: {
    imagePicker,
    plantillaLight,
    landingLight,
  },
};
</script>
