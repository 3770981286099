<template>
    <div style="display: flex; margin: 0px; text-align: left; width: 100%;">
        <a @click="toggleRoute('/account#profile')" style="display: flex; margin: 0px; text-align: left; width: 100%;">
            <vs-avatar size="40" style="border-radius: 50%;">
                <img :src="profilePicture" alt="">
            </vs-avatar>
            <div style="margin-left: 10px; line-height: 12px; text-align: left;">
                {{ user.account_details?.account_name }}<br />
                <font size="1"> {{ user.email }}</font><br />
                <font size="1" :style="{ color: planColor }">Plan: <b>{{ formattedPlanName }}</b></font><br />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: ['user'],
    computed: {
        profilePicture() {
            return this.user.account_details?.account_picture || require('@/assets/images/default-avatar-linkcard.png');
        },
        formattedPlanName() {
            const planName = this.user.stripe_details?.stripe_plan?.name;
            this.$gtag.set("user_properties", {
                plan: planName,
                pago: this.user.stripe_details?.stripe_plan?.price ? true : false,
            });
            this.$gtag.event('set_user_properties', {
                plan: planName,
                pago: this.user.stripe_details?.stripe_plan?.price ? true : false,
            })
            if (!planName) return 'Linkcard Pro';
            if (planName === 'Owner') return 'Linkcard Infinity';
            return planName.includes('Freemium') ? 'Linkcard Freemium' : 'Linkcard Pro';
        },
        planColor() {
            const planName = this.formattedPlanName;
            if (planName.includes('Freemium')) return 'rgb(0, 144, 228)';
            if (planName === 'Linkcard Infinity') return '#02B768';
            return '#F531A6';
        }
    }
}
</script>