import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b4e7604a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _90565506 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _1ac6f295 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _866dd17c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _09feb69b = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _c9e16132 = () => interopDefault(import('../pages/import.vue' /* webpackChunkName: "pages/import" */))
const _d96aa54e = () => interopDefault(import('../pages/linkcards_no.vue' /* webpackChunkName: "pages/linkcards_no" */))
const _500d2497 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _34b41302 = () => interopDefault(import('../pages/login_old.vue' /* webpackChunkName: "pages/login_old" */))
const _02af1268 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _64f37c96 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _48301366 = () => interopDefault(import('../pages/signature.vue' /* webpackChunkName: "pages/signature" */))
const _7d040b06 = () => interopDefault(import('../pages/signatures.vue' /* webpackChunkName: "pages/signatures" */))
const _53c75bc8 = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _29c90f20 = () => interopDefault(import('../pages/uikit.vue' /* webpackChunkName: "pages/uikit" */))
const _68614894 = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _b7718220 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _c1716c14 = () => interopDefault(import('../pages/leads/landings.vue' /* webpackChunkName: "pages/leads/landings" */))
const _b5c25c42 = () => interopDefault(import('../pages/leads/linkcards.vue' /* webpackChunkName: "pages/leads/linkcards" */))
const _af6512e4 = () => interopDefault(import('../pages/leads/settings_landings.vue' /* webpackChunkName: "pages/leads/settings_landings" */))
const _86438f72 = () => interopDefault(import('../pages/leads/settings_linkcards.vue' /* webpackChunkName: "pages/leads/settings_linkcards" */))
const _2e51bf58 = () => interopDefault(import('../pages/loyalty/loyaltys.vue' /* webpackChunkName: "pages/loyalty/loyaltys" */))
const _5166b77c = () => interopDefault(import('../pages/loyalty/memberships.vue' /* webpackChunkName: "pages/loyalty/memberships" */))
const _1028c036 = () => interopDefault(import('../pages/onboarding/landing.vue' /* webpackChunkName: "pages/onboarding/landing" */))
const _9d9b08a8 = () => interopDefault(import('../pages/onboarding/linkcard.vue' /* webpackChunkName: "pages/onboarding/linkcard" */))
const _252a7a94 = () => interopDefault(import('../pages/onboarding/loyalty.vue' /* webpackChunkName: "pages/onboarding/loyalty" */))
const _7d3899c8 = () => interopDefault(import('../pages/sector/import.vue' /* webpackChunkName: "pages/sector/import" */))
const _3b1cc832 = () => interopDefault(import('../pages/stats/linkcards.vue' /* webpackChunkName: "pages/stats/linkcards" */))
const _256f8fda = () => interopDefault(import('../pages/wizard/data.js' /* webpackChunkName: "pages/wizard/data" */))
const _7b012916 = () => interopDefault(import('../pages/sector/sectors/landings.vue' /* webpackChunkName: "pages/sector/sectors/landings" */))
const _2e2a3f80 = () => interopDefault(import('../pages/sector/sectors/linkcards.vue' /* webpackChunkName: "pages/sector/sectors/linkcards" */))
const _7fc817a0 = () => interopDefault(import('../pages/wizard/steps/sector.vue' /* webpackChunkName: "pages/wizard/steps/sector" */))
const _8982b522 = () => interopDefault(import('../pages/wizard/steps/templates.vue' /* webpackChunkName: "pages/wizard/steps/templates" */))
const _187305f4 = () => interopDefault(import('../pages/wizard/steps/type.vue' /* webpackChunkName: "pages/wizard/steps/type" */))
const _ac327b5c = () => interopDefault(import('../pages/wizard/steps/upgrade.vue' /* webpackChunkName: "pages/wizard/steps/upgrade" */))
const _745f06b3 = () => interopDefault(import('../pages/sector/sectors/_type/index.vue' /* webpackChunkName: "pages/sector/sectors/_type/index" */))
const _7b514f64 = () => interopDefault(import('../pages/stats/landings/_sector.vue' /* webpackChunkName: "pages/stats/landings/_sector" */))
const _2cb432a6 = () => interopDefault(import('../pages/sector/sectors/_type/_sector.vue' /* webpackChunkName: "pages/sector/sectors/_type/_sector" */))
const _05efdb46 = () => interopDefault(import('../pages/editor/_id/index.vue' /* webpackChunkName: "pages/editor/_id/index" */))
const _022aa43b = () => interopDefault(import('../pages/leads/_sector.vue' /* webpackChunkName: "pages/leads/_sector" */))
const _5474b175 = () => interopDefault(import('../pages/loyalty/_loyalty/index.vue' /* webpackChunkName: "pages/loyalty/_loyalty/index" */))
const _3b6afc48 = () => interopDefault(import('../pages/sectors/_type/index.vue' /* webpackChunkName: "pages/sectors/_type/index" */))
const _0670531e = () => interopDefault(import('../pages/templates/_type.vue' /* webpackChunkName: "pages/templates/_type" */))
const _e233c3dc = () => interopDefault(import('../pages/loyalty/_loyalty/import.vue' /* webpackChunkName: "pages/loyalty/_loyalty/import" */))
const _4c793f7c = () => interopDefault(import('../pages/loyalty/_loyalty/members.vue' /* webpackChunkName: "pages/loyalty/_loyalty/members" */))
const _09079ec2 = () => interopDefault(import('../pages/loyalty/_loyalty/stats.vue' /* webpackChunkName: "pages/loyalty/_loyalty/stats" */))
const _e11d5086 = () => interopDefault(import('../pages/sector/_sector/landings.vue' /* webpackChunkName: "pages/sector/_sector/landings" */))
const _8b930610 = () => interopDefault(import('../pages/sector/_sector/linkcards.vue' /* webpackChunkName: "pages/sector/_sector/linkcards" */))
const _19732f58 = () => interopDefault(import('../pages/linkcards/_uid/_sid/index.vue' /* webpackChunkName: "pages/linkcards/_uid/_sid/index" */))
const _268f7b93 = () => interopDefault(import('../pages/sharing/_user/_id/index.vue' /* webpackChunkName: "pages/sharing/_user/_id/index" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _b4e7604a,
    name: "account"
  }, {
    path: "/admin",
    component: _90565506,
    name: "admin"
  }, {
    path: "/contacts",
    component: _1ac6f295,
    name: "contacts"
  }, {
    path: "/dashboard",
    component: _866dd17c,
    name: "dashboard"
  }, {
    path: "/directory",
    component: _09feb69b,
    name: "directory"
  }, {
    path: "/import",
    component: _c9e16132,
    name: "import"
  }, {
    path: "/linkcards_no",
    component: _d96aa54e,
    name: "linkcards_no"
  }, {
    path: "/login",
    component: _500d2497,
    name: "login"
  }, {
    path: "/login_old",
    component: _34b41302,
    name: "login_old"
  }, {
    path: "/logout",
    component: _02af1268,
    name: "logout"
  }, {
    path: "/notifications",
    component: _64f37c96,
    name: "notifications"
  }, {
    path: "/signature",
    component: _48301366,
    name: "signature"
  }, {
    path: "/signatures",
    component: _7d040b06,
    name: "signatures"
  }, {
    path: "/solutions",
    component: _53c75bc8,
    name: "solutions"
  }, {
    path: "/uikit",
    component: _29c90f20,
    name: "uikit"
  }, {
    path: "/users",
    component: _68614894,
    name: "users"
  }, {
    path: "/wizard",
    component: _b7718220,
    name: "wizard"
  }, {
    path: "/leads/landings",
    component: _c1716c14,
    name: "leads-landings"
  }, {
    path: "/leads/linkcards",
    component: _b5c25c42,
    name: "leads-linkcards"
  }, {
    path: "/leads/settings_landings",
    component: _af6512e4,
    name: "leads-settings_landings"
  }, {
    path: "/leads/settings_linkcards",
    component: _86438f72,
    name: "leads-settings_linkcards"
  }, {
    path: "/loyalty/loyaltys",
    component: _2e51bf58,
    name: "loyalty-loyaltys"
  }, {
    path: "/loyalty/memberships",
    component: _5166b77c,
    name: "loyalty-memberships"
  }, {
    path: "/onboarding/landing",
    component: _1028c036,
    name: "onboarding-landing"
  }, {
    path: "/onboarding/linkcard",
    component: _9d9b08a8,
    name: "onboarding-linkcard"
  }, {
    path: "/onboarding/loyalty",
    component: _252a7a94,
    name: "onboarding-loyalty"
  }, {
    path: "/sector/import",
    component: _7d3899c8,
    name: "sector-import"
  }, {
    path: "/stats/linkcards",
    component: _3b1cc832,
    name: "stats-linkcards"
  }, {
    path: "/wizard/data",
    component: _256f8fda,
    name: "wizard-data"
  }, {
    path: "/sector/sectors/landings",
    component: _7b012916,
    name: "sector-sectors-landings"
  }, {
    path: "/sector/sectors/linkcards",
    component: _2e2a3f80,
    name: "sector-sectors-linkcards"
  }, {
    path: "/wizard/steps/sector",
    component: _7fc817a0,
    name: "wizard-steps-sector"
  }, {
    path: "/wizard/steps/templates",
    component: _8982b522,
    name: "wizard-steps-templates"
  }, {
    path: "/wizard/steps/type",
    component: _187305f4,
    name: "wizard-steps-type"
  }, {
    path: "/wizard/steps/upgrade",
    component: _ac327b5c,
    name: "wizard-steps-upgrade"
  }, {
    path: "/sector/sectors/:type",
    component: _745f06b3,
    name: "sector-sectors-type"
  }, {
    path: "/stats/landings/:sector?",
    component: _7b514f64,
    name: "stats-landings-sector"
  }, {
    path: "/sector/sectors/:type?/:sector",
    component: _2cb432a6,
    name: "sector-sectors-type-sector"
  }, {
    path: "/editor/:id",
    component: _05efdb46,
    name: "editor-id"
  }, {
    path: "/leads/:sector?",
    component: _022aa43b,
    name: "leads-sector"
  }, {
    path: "/loyalty/:loyalty",
    component: _5474b175,
    name: "loyalty-loyalty"
  }, {
    path: "/sectors/:type",
    component: _3b6afc48,
    name: "sectors-type"
  }, {
    path: "/templates/:type?",
    component: _0670531e,
    name: "templates-type"
  }, {
    path: "/loyalty/:loyalty?/import",
    component: _e233c3dc,
    name: "loyalty-loyalty-import"
  }, {
    path: "/loyalty/:loyalty?/members",
    component: _4c793f7c,
    name: "loyalty-loyalty-members"
  }, {
    path: "/loyalty/:loyalty?/stats",
    component: _09079ec2,
    name: "loyalty-loyalty-stats"
  }, {
    path: "/sector/:sector?/landings",
    component: _e11d5086,
    name: "sector-sector-landings"
  }, {
    path: "/sector/:sector?/linkcards",
    component: _8b930610,
    name: "sector-sector-linkcards"
  }, {
    path: "/linkcards/:uid?/:sid",
    component: _19732f58,
    name: "linkcards-uid-sid"
  }, {
    path: "/sharing/:user?/:id",
    component: _268f7b93,
    name: "sharing-user-id"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
