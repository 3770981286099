<template>
    <div>
      <label>{{ label || $t('pickers.videoEmbed') }}</label>
      <div class="embed_picker_container">
        <div class="input-group mb-3">
          <input 
            type="text" 
            class="form-control" 
            v-model="videoLink" 
            :placeholder="'Youtube URL / Vimeo URL'"
            @input="validateAndEmit" 
          />
          <div class="input-group-append">
            <button 
              class="btn btn-outline-secondary" 
              type="button" 
              @click="clearVideo"
              v-if="videoLink"
            >
              <font-awesome-icon :icon="['fas','times']"/>
            </button>
          </div>
        </div>
        
        <div v-if="embedUrl" class="video_preview mt10">
          <iframe 
            :src="embedUrl" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; height: 200px;"
          ></iframe>
        </div>
        
        <div class="mt10" v-if="error">
          <div class="alert alert-danger">
            {{ error }}
          </div>
        </div>
        
        <div class="text-muted small mt-2">
          {{ $t('pickers.videoSupportedLinks') }} <b>YouTube</b>, <b>Vimeo</b>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['value', 'label'],
    data() {
      return {
        videoLink: this.value || '',
        embedUrl: '',
        error: null
      }
    },
    mounted() {
      if (this.value) {
        this.validateAndEmit();
      }
    },
    methods: {
        validateAndEmit() {
            this.error = null;
            
            if (!this.videoLink) {
                this.embedUrl = '';
                this.$emit('input', '');
                return;
            }
            
            const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?(.*&)*v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(&.*)?$/;
            const youtubeMatch = this.videoLink.match(youtubeRegex);
            
            const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)?(\d+)(\/.*|&.*)?$/;
            const vimeoMatch = this.videoLink.match(vimeoRegex);
            
            if (youtubeMatch) {
                const videoId = youtubeMatch[5];
                this.embedUrl = `https://www.youtube.com/embed/${videoId}`;
                this.$emit('input', this.videoLink);
                this.$emit('video-selected', true);
            } else if (vimeoMatch) {
                const videoId = vimeoMatch[4];
                this.embedUrl = `https://player.vimeo.com/video/${videoId}`;
                this.$emit('input', this.videoLink);
                this.$emit('video-selected', true);
            } else if (this.videoLink) {
                const videoId = this.getYoutubeId(this.videoLink);
                if (videoId) {
                this.embedUrl = `https://www.youtube.com/embed/${videoId}`;
                this.$emit('input', this.videoLink);
                this.$emit('video-selected', true);
                } else {
                this.error = this.$t('errors.invalidVideoUrl');
                this.embedUrl = '';
                }
            }
        },
        getYoutubeId(url) {
            if (!url) return '';
            
            try {
                if (url.includes('youtube.com/watch')) {
                const urlObj = new URL(url);
                return urlObj.searchParams.get('v');
                }
                
                if (url.includes('youtu.be/')) {
                const parts = url.split('youtu.be/')[1].split(/[?&#]/);
                return parts[0];
                }
            } catch (e) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                const match = url.match(regExp);
                return (match && match[2].length === 11) ? match[2] : '';
            }
            
            return '';
        },
        clearVideo() {
            this.videoLink = '';
            this.embedUrl = '';
            this.error = null;
            this.$emit('input', '');
            this.$emit('video-selected', false); 
        }
    },
    watch: {
      value(newVal) {
        if (this.videoLink !== newVal) {
          this.videoLink = newVal || '';
          this.validateAndEmit();
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .video_preview {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
  }
  .mt10 {
    margin-top: 10px;
  }
  </style>