<template>
  <div id="edicionMenuTop" class="edicion_menu">
    <nav class="edicion_top_nav">
      <div class="row">
        <div class="col-lg-4 pr-0 pl-0 etn_col">
          <ul class="etn_menu etn_lt">
            <li class="etn_short">
              <a @click="closeEditor()"
                ><font-awesome-icon
                  icon="xmark"
                  style="margin-bottom: 2px; font-size: 45px; height: 22px!important;"
              /></a>
            </li>
            <li
              :class="{ active: $store.state.sobreComp === 'leftColSobreDA' }"
            >
              <a @click="enable_Info()"
                >
                <Icon icon="ph:computer-tower-bold" style="font-size: 24px;"/>

                <span class="etn_hide">
                  {{ $t("bc.content") }}</span
                ></a
              >
            </li>
            <li
              v-if="!cardVal.isLanding"
              id="v-step-3"
              :class="{ active: $store.state.sobreComp === 'leftColSobreDD' }"
            >
              <a @click="enable_Design()"
                ><font-awesome-icon icon="paint-brush" /><span class="etn_hide">
                  {{ $t("bc.design") }}</span
                ><!-- <font-awesome-icon v-if="!$util.isTrialOrPro()" :icon="['fas','lock']" />--></a
              >
            </li>
            <!-- <li v-if="!$util.isMobile()" id="v-step-6" :class="{   active: $store.state.sobreComp === 'leftColSettings' }"><a  @click="$util.switchCompInfo('settings',$bus)"><font-awesome-icon :icon="['fas','cog']" /><span class="etn_hide"> {{$t('bc.settings')}}</span></a></li>-->
            <li
              v-if="!(cardVal.loyalty_id >= 0)"
              id="v-step-6"
              :class="{ active: $store.state.sobreComp === 'leftColSettings' }"
            >
              <a @click="$util.switchCompInfo('settings', $bus)"
                >
                <Icon icon="ph:faders-fill" style="font-size: 24px;"/>
                <span
                  class="etn_hide"
                >
                {{ $t("bc.settings") }}</span
                ></a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-8 pl-0 etn_col">
          <ul class="etn_menu etn_rt">
            <!-- <li class="etn_short"><a @click="undo()"><font-awesome-icon :icon="['fas','undo']" /></a></li>
                      <li class="etn_short"><a @click="redo()"><font-awesome-icon :icon="['fas','redo']" /></a></li>-->
            <li id="v-step-8" v-if="cardVal.id">
              <a @click="handleShare()"
              >
              <Icon icon="ph:share-network-bold" style="font-size: 24px;" />
              <span
                  class="etn_hide"
                >
                  {{ $t("bc.share") }}</span
                ></a
              >
            </li>
            <li id="v-step-8" v-if="!(cardVal.loyalty_id >= 0)">
              <a @click="openLinkcard()"
                >
              <Icon icon="ph:eye-bold" style="font-size: 24px;" />
                <span
                  class="etn_hide"
                >
                  {{ $t("bc.preview") }}</span
                ></a
              >
            </li>
            <li id="v-step-7" class="btn-success_container" style="">
              <a
                v-if="checktime"
                class="btn-success w-100"
                style="background: green"
                ><font-awesome-icon icon="check"
              /></a>
              <a v-else-if="loading" class="btn-success w-100"
                ><font-awesome-icon icon="circle-notch" spin
              /></a>
              <a
                v-else-if="$store.getters.hasBeenEdited"
                @click="guardarLinckard()"
                class="btn-success w-100"
                ><font-awesome-icon :icon="['fas', 'check']" /><span
                  class="etn_hide"
                >
                  {{ $t("bc.publicar") }}
                </span></a
              >
              <a
                v-else
                class="btn-success w-100"
                style="background: #686868; height: 100%"
                >
              <Icon icon="ph:floppy-disk-back-bold" style="font-size: 24px;" />
                <span
                  class="etn_hide"
                >
                  {{ $t("bc.sincambios") }}
                </span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import LeftNav from "@/components/LeftNav.vue";
import modals from "@/components/modals/modals.vue";
import { auth, usersCollection, algolia } from "@/services/firebaseConfig.js";
import algoliasearch from "algoliasearch";
import { Icon } from "@iconify/vue2"

//Mobile

//right

//Center
import Tarjeta from "@/layouts/main_Preview/main_Preview.vue";

import notfound from "@/components/notfound";
export default {
  data() {
    return {
      getUser: this.$store.state.userProfile,
      rolling: false,
      cardVal: this.$store.getters.editingLinckard,
      editCual: "editEmpty",
      loading: false,
      checktime: false,
      currentComp: this.$util.isLoyaltyCustomizer()
        ? "leftColMembre"
        : "leftColInfo",
    };
  },
  beforeRouteLeave(to, from, next) {
    this.cerrarEditorVerify(null, next);
  },
  beforeMount() {
    this.$store.state.editor.isSaving = false;
    window.addEventListener("beforeunload", this.documentClick);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.documentClick);
  },
  beforeMount() {
    if (!this.cardVal) this.$util.gotoHOME();
  },
  mounted() {
    var dis = this;
    //Reset all
    this.$store.state.sobreComp = "leftColSobreDA";
    this.$util.initPage(this.getUser, null, false, this.$validator);

    console.log("Aqui", this.cardVal);
  },
  created() {
    var dis = this;
    this.$bus.$on("switchCompRight", (comp) => {
      this.currentCompRight = comp;
    });

    this.$bus.$on("switchComp", (comp) => {
      this.currentComp = comp.replace("preview", "leftCol");
    });

    if (this.$util.isCustomizer()) {
      this.$bus.$off("undo");
      this.$bus.$off("redo");

      this.$bus.$on("undo", (comp) => {
        this.undo();
      });
      this.$bus.$on("redo", (comp) => {
        this.redo();
      });
    }
  },
  methods: {
    async handleShare() {
      try {
          if (!this.cardVal.share_url) {
              // si no existe share_url
              await this.guardarLinckard(true);
          }
          // tarjeta actualizada
          if (this.cardVal) {
              this.$util.modalShare(this.cardVal, this.$modal);
          } else {
              throw new Error('No se pudo obtener la tarjeta actualizada');
          }
      } catch (error) {
          console.error('Error al compartir:', error);
          this.$util.notif_error(this.$t('notis.error_compartir'));
      }
    },
    enable_Info() {
      this.$util.switchCompInfo("info", this.$bus);
    },
    enable_Design() {
      this.$util.switchCompInfo("design", this.$bus);
    },
    openLinkcard(id) {
      let elid = this.$route.params.id;
      window.open(
        this.$util.cardURL() + this.getUser.username + "/" + this.cardVal.id
      );
    },
    closeAllDivs() {
      this.$store.state.sobreComp == "leftColSobreDA";
      this.$store.state.infoMode = "content";
      this.currentComp = "leftColSobre";
      this.$util.closeAllDivs();
    },
    switchComponentLeft(comp) {
      this.$bus.$emit("switchCompLeft", comp);
      this.currentComp = comp;
    },
    menuToggle() {
      this.$util.toggleDiv("menu_open");
    },
    undo() {
      this.$bus.$emit("undo");
    },
    redo() {
      this.$bus.$emit("redo");
    },
    cerrarEditorVerify(comp = null, next = null) {
      var dis = this;
      let devuelve = false;
      if (this.$store.getters.hasBeenEdited) {
        this.$modal.show("dialog", {
          title: this.$t("notis.atencion"),
          text: dis.$t("notis.seguroque"),
          buttons: [
            {
              title: dis.$t("notis.si"),
              handler: () => {
                dis.$store.commit("resetColaEditar");
                dis.$modal.hide("dialog");
                if (next) {
                  next();
                } else if (comp) {
                  dis.mainComp = comp.comp;
                  dis.getUser = comp.profile;
                  devuelve = true;
                }
              },
            },
            { title: dis.$t("general.no") },
          ],
        });
      } else if (comp) {
        dis.mainComp = comp.comp;
        dis.getUser = comp.profile;
        devuelve = true;
      } else if (next) {
        next();
      }
    },

    documentClick(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    closeEditor() {
        if (this.$store.state.currentType == 'loyalty') {
          this.$store.commit('clearCurrentType');
          if(this.cardVal.loyalty_id || this.cardVal.loyalty_id == 0){
            this.$router.push(`/loyalty/${this.cardVal.loyalty_id}`);
          } else {
            this.$router.push(`/loyalty/loyaltys`);
          }
          return;
        }

        const currentType = (this.cardVal?.sector_id && this.getUser.sectors.find(s => s.id == this.cardVal.sector_id)?.type) || 
                          'bc';
        this.$store.commit('clearCurrentType');
        if(this.cardVal.sector_id || this.cardVal.sector_id===0){
            this.$router.push(`/sector/sectors/${currentType}/${this.cardVal.sector_id}`);
          } else {
            this.$router.push(`/sector/sectors/${currentType}`);
          }
    },
    async guardarLinckard(forceUpdate = false) {
      var dis = this;
      this.loading = true;
      let nueva = this.$store.getters.editingLinckard;
      console.log('editingLinkcard', this.$store.getters.editingLinckard)
      if (!forceUpdate && !this.$store.getters.hasBeenEdited) {
        alert("No changes to save!");
        this.loading = false;
        return;
      }
      if (this.$util.isLoyaltyCustomizer()) await this.guardarLoyalty(nueva);
      else await this.guardarBC(nueva);

      dis.checktime = true;
      dis.loading = false;
      setTimeout(() => {
        dis.checktime = false;
      }, 1000);
    },
    async guardarLoyalty(nueva) {
      var dis = this;
      this.$store.state.editor.isSaving = true;

      //upload de imagenes
      nueva = await this.$util.uploadImages(nueva);
      let loyalty_id = this.$route.params.id;
      let index = this.getUser.loyaltys.findIndex(function (i) {
        return i.id == loyalty_id;
      });
      this.getUser.loyaltys[index].linckard = nueva;

      //GUARDAR referencia paralela a sobredrags+
      let sobredrags = JSON.parse(
        JSON.stringify(this.getUser.loyaltys[index].linckard.sobredrags)
      );
      this.getUser.loyaltys[index].linckard.sobredrags_auto = [];
      this.getUser.loyaltys[index].linckard.sobredrags = [];
      for (let i in sobredrags) {
        let elem = sobredrags[i];
        elem.index_pos = i;
        if (elem.auto) {
          this.getUser.loyaltys[index].linckard.sobredrags_auto.push(elem);
        } else {
          this.getUser.loyaltys[index].linckard.sobredrags.push(elem);
        }
      }
      usersCollection
        .doc(this.getUser.uid)
        .update({ loyaltys: this.getUser.loyaltys })
        .then(function () {
          dis.$store.state.editor.isSaving = false;
          dis.$util.notif_success(dis.$t("notis.linckardsave"));
          dis.$store.commit("resetSaveLinckardVersioning", nueva);
        })
        .catch((error) => {
          dis.$store.state.editor.isSaving = false;
          dis.$util.notif_error(error.message);
        });
    },
    async guardarBC(nueva) {
      console.log('guardarBC nueva', nueva)
      try {
        if (!this.$util.verificarLinkcard(nueva, true)) return;

        if (nueva.seo && !nueva.seo.img && this.$store.getters.editingLinckard.seo && this.$store.getters.editingLinckard.seo.img) {
          console.log('restaurando seo.img que se perdio:', this.$store.getters.editingLinckard.seo.img);
          nueva.seo.img = JSON.parse(JSON.stringify(this.$store.getters.editingLinckard.seo.img));
        }
        console.log('nueva.seo', nueva.seo)

        let mostrarmodal = nueva.id == undefined;
        nueva = await this.$util.guardarLinckardGeneral(nueva);
        if (mostrarmodal)
          this.$modal.show("modal-share", { linkcard: nueva, publish: true });

      } catch(error) {
        console.error('Error al guardar BC:', error);
        this.$util.notif_error(this.$t('notis.error_guardar'));
        return null;
      }
    },
  },
  watch: {
    mainComp(val) {
      this.$store.commit("setMainComp", val);
    },
  },

  components: {
    Tarjeta,
    LeftNav,
    modals,
    notfound,
    Icon
  },
};
</script>

<style scoped>
.etn_menu .active {
  background: #686868;
}
.etn_menu .active .etn_hide,
.etn_menu .active a .svg {
  color: white !important;
}
@media (max-width: 799px) {
  .edicion_top_nav .etn_menu > li.btn-success_container {
    min-width: 80px !important;
    width: 80px !important;
  }
  .edicion_top_nav .etn_menu > li > a.btn-success {
    width: 80px !important;
    min-width: 80px !important;
  }
}
</style>
