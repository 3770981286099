<template>
    <div class="edicion_block">
        <div @click="show.s1 = !show.s1" class="navcola manito" style="border-top:0px !important;">
            <h4>{{ $t('bc.content') }}</h4>
            <font-awesome-icon :icon="['fas', 'chevron-' + (show.s1 ? 'up' : 'down')]" />
        </div>
        <div :class="'contenido ' + show.s1" v-show-slide="show.s1">
            <div class="row form_row">
                <div class="col-12">
                    <label>{{ $t('general.nombre') }}</label>
                    <div class="form-group">
                        <input class="form-control" type="text" maxlength="50" :placeholder="$t('general.entervalue')"
                            v-model="valor.value">
                    </div>
                </div>
            </div>
            <iconPicker :value="value.icon" v-model="value.icon"></iconPicker>
            <div class="row form_row mb20">
                <div class="col-12">
                    <label>{{ $t('general.action') }}</label>
                    <select class="form-control" id="comboMostrar" v-model="tipo">
                        <option value='url'>{{ $t('general.action1') }}</option>
                        <option value='whatsapp'>{{ $t('general.action2') }}</option>
                        <option value='email'>{{ $t('general.action3') }}</option>
                        <option value='sms'>{{ $t('general.action4') }}</option>
                        <option value='share'>{{ $t('general.action5') }}</option>
                        <option v-if="!this.cardVal.loyalty_id" value='form'>{{ $t('general.action6') }}</option>
                        <option value='call'>{{ $t('general.action7') }}</option>
                    </select>
                </div>
            </div>
            <div v-if="tipo == 'url' || tipo == 'email'" class="row form_row">
                <div class="col-12">
                    <!--<label>{{$t('general.url')}}</label>-->
                    <div class="form-group">
                        <input name="url" type="text" maxlength="500" class="form-control  "
                            :placeholder="$t('general.entervalue')" v-model="valor.url">
                    </div>
                </div>
            </div>
            <div v-if="tipo == 'whatsapp' || tipo == 'call' || tipo == 'sms'" class="row form_row">
                <div class="col-12">
                    <label>{{ $t('general.url') }}</label>
                    <div class="form-group">
                        <vue-phone-number-input v-model="valor.url" :valid-color="'none'"
                            :default-country-code="valor.valor_code" :placeholder="$t('info.telmovil')"
                            @update="onUpdateMobile" :translations="$t('inputphone_mobile')" />
                    </div>
                </div>
            </div>
        </div>
        <div @click="show.s2 = !show.s2" class="navcola manito" style="border-top:0px !important;">
            <h4>{{ $t('bc.design') }}</h4>
            <font-awesome-icon :icon="['fas', 'chevron-' + (show.s2 ? 'up' : 'down')]" />
        </div>
        <div :class="'contenido ' + show.s2" v-show-slide="show.s2">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker v-model="valor.family" />
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"
                        v-model="valor.weight" />
                </div>
                <div class="col-lg-6">
                    <sizePicker v-model="valor.size" />
                </div>
            </div>

            <div class="row form_row">

                <div class="col-lg-12">
                    <colorPicker :color="valor.color" v-model="valor.color" />
                </div>
                <div class="col-lg-12">
                    <colorPicker :label="$t('pickers.colorfondo')" :color="valor.background" v-model="valor.background" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { Icon } from "@iconify/vue2"
import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'
import fontPicker from '../dinputs/fontPicker'
import iconPicker from '../dinputs/iconPicker'

export default {
    props: ['value'],
    data() {
        return {
            show: { s1: true, s2: false },
            selected: 'url',
            valor: this.value,
            tipo: this.value.type ? this.value.type : 'url',
            cardVal:this.$store.getters.editingLinckard,
        }
    },
    beforeMount() {
        if (this.valor.length > 5) this.valor.url = this.getNumberOK(this.valor)
        if (!this.valor.valor_code) this.valor.valor_code = this.$store.getters.countryCode
    },
    methods: {
        getNumberOK(myString) {
            if (!myString) return '';//impotante devolver stirng
            var str = myString.substring(myString.indexOf(' ') + 1)
            return str
        },
        onUpdateMobile(payload) {
            if (payload.formatInternational) this.valor.url = payload.formatInternational
            if (payload.countryCode) this.valor.valor_code = payload.countryCode
        },
    },
    watch: {
        tipo(val) {
            this.valor.type = val
        }
    },
    components: {
        'colorPicker': colorPicker,
        'sizePicker': sizePicker,
        'fontPicker': fontPicker,
        Icon,iconPicker
    }
}
</script>

